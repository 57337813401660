import { PageId } from '../../constants';
import { ComponentStructure } from '../../migration/domain';
import { getNewServicePageData } from '../service-page-migration/settings-data-transpiler';
import { getNewServicePageStyles } from '../service-page-migration/styles-transpiler';
import { BasePageMigrator } from './base-page-migrator';
import { EditorScriptApi } from '../../api/api';

export class ServicePageMigrator extends BasePageMigrator {
  constructor(
    protected editorSDK,
    protected appToken,
    protected instance,
    private editorScriptApi: EditorScriptApi,
  ) {
    super(editorSDK, appToken, instance);
  }

  public async shouldMigrate(): Promise<boolean> {
    const allSitePages = await this.getAllSitePages();
    return !allSitePages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
    );
  }

  public async execute() {
    await this.addBookingsPage(PageId.BOOKINGS_SERVICE_PAGE);

    const siteStructure = await this.getSiteStructure();
    const compStructuresCheckout: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.bookingCheckoutWidgetId,
    );
    const compStructuresServicePage: ComponentStructure = this.findWidgetStructure(
      siteStructure,
      this.widgetId,
    );

    if (compStructuresCheckout.style?.style?.properties) {
      console.log('Old Service Page Properties:');
      console.log(compStructuresCheckout);
      console.table(compStructuresCheckout.style.style.properties);
      console.log('New Service Page structure: (Before Change)');
      console.log(compStructuresServicePage);
      console.table(compStructuresServicePage.style?.style?.properties);
      const newServicePageData = getNewServicePageData(
        compStructuresCheckout.style.style.properties,
      );
      console.log('Layouts to write:');
      console.table(newServicePageData);

      if (newServicePageData) {
        await this.updateComponentSettings(
          compStructuresServicePage.id,
          newServicePageData,
        );
      }
      const newServicePageStyles = getNewServicePageStyles(
        compStructuresCheckout.style.style.properties,
      );
      console.log('Styles to write:');
      console.table(newServicePageStyles);

      if (newServicePageStyles) {
        await this.updateComponentStyles(
          compStructuresServicePage.id,
          newServicePageStyles,
        );
      }
    } else {
      console.log('Old service page is plain, no styles to migrate.');
    }

    await this.editorScriptApi.migrateMediaGallery();
  }

  protected get widgetId() {
    return 'a91a0543-d4bd-4e6b-b315-9410aa27bcde';
  }
}
